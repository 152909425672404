$(document).ready(function(){
	
	var browser=browserCheck();
	var os=osCheck();
	var device=deviceCheck();
	$("html").addClass(browser).addClass(os);


  // <a href="#***">の場合、スクロール処理を追加
  $('a[href*="#"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
  		var $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name="' + this.hash.slice(1) +'"]');
      if ($target.length) {
      	var targetOffset = $target.offset().top;
        $('html,body').animate({ scrollTop: targetOffset }, 800, 'easeOutExpo');
				return false;
      }
    }
  });
	$("#menu-btn").click(function(){
	  $("#menu").toggleClass("open");
  });

	if($("#achieve").length){
	  var ctx1 = document.getElementById("chart1").getContext('2d');
	  var ctx2 = document.getElementById("chart2").getContext('2d');
		var myChart1 = new Chart(ctx1, {
	    type: 'pie',
	    data: {
				labels: ["P-I", "P-II", "P-III","BE","BA","臨床研究", "特定臨床研究"],
				datasets: [{
						data: [11, 7, 15, 15, 11, 30, 11],
	            backgroundColor: ["#e5bac7","#dea8b8","#d795a8","#d08299","#c96f8a","#c25c7a","#bb4a6b"],
	            borderWidth: 0
	        }]
	    },
	    options: {
		    legend:{
			    position:"right",
			    labels:{
				    boxWidth:12,
			    },
			    onClick: function(){ return false; }
		    },
		    tooltips:{
			    backgroundColor:"#ffffff",
			    bodyFontColor:"#555555",
			    caretSize:4,
			    cornerRadius:2,
			    callbacks: {
	          label: function (tooltipItem, data) {
	            return data.labels[tooltipItem.index]
	              + ": "
	              + data.datasets[0].data[tooltipItem.index]
	              + "%"; //ここで単位を付けます
	          }
	        }
		    }
	    },
	    plugins: [dataLabelPlugin]
		});
		var myChart2 = new Chart(ctx2, {
	    type: 'pie',
	    data: {
				labels: ["ワクチン","外皮用薬","中枢神経系", "感染症薬", "眼科","医療用具","アレルギー","その他"],
				datasets: [{
						data: [18, 26, 7, 4, 4, 7, 4, 30],
	            backgroundColor: ["#e5bac7","#dea8b8","#d795a8","#d08299","#c96f8a","#c25c7a","#bb4a6b","#ae234c"],
	            borderWidth: 0
	        }]
	    },
	    options: {
		    legend:{
			    position:"right",
			    labels:{
				    boxWidth:12,
			    },
			    onClick: function(){ return false; }
		    },
		    tooltips:{
			    backgroundColor:"#ffffff",
			    bodyFontColor:"#555555",
			    caretSize:4,
			    cornerRadius:2,
			    callbacks: {
	          label: function (tooltipItem, data) {
	            return data.labels[tooltipItem.index]
	              + ": "
	              + data.datasets[0].data[tooltipItem.index]
	              + "%"; //ここで単位を付けます
	          }
	        }
		    }
	    },
	    plugins: [dataLabelPlugin]
		});
  }
	if($(".validationform").length){
		//inputの下にエラー
		var validation = $(".validationform").exValidation({
			errInsertPos :"after",
			errPosition :"fixed",
		  stepValidation: true,
		  errHoverHide:false,
		  errTipCloseBtn:false,
		  scrollAdjust:0,
		  errMsgPrefix : "",
		});
		if($("#chkprivacy").prop('checked')){
			$("#submit").prop('disabled',false);
			$(".submit-wrap").removeClass("disabled");
		}else{
			$("#submit").prop('disabled',true);
			$(".submit-wrap").addClass("disabled");
		}
		$("#chkprivacy").click(function(){
			if($("#chkprivacy").prop('checked')){
				$("#submit").prop('disabled',false);
				$(".submit-wrap").removeClass("disabled");
			}else{
				$("#submit").prop('disabled',true);
				$(".submit-wrap").addClass("disabled");
			}		
		});
		
		
		//Edgeでラジオボタンの値が消える対策
    //各ラジオボタンの設定（value値記録）
    $('input[type=radio]').on('change', function (e) {
        var $self = $(this);
        var valueData = $self.val();
        var nameData = $self.attr('name');
        var $myDataSaveField = $('[data-name=' + nameData + ']');
        $myDataSaveField.val(valueData);
    });

    //隠しフィールドの値から復帰処理
    $('[data-name]').each(function () {
        var $self = $(this);
        var valueData = $self.val();
        if(valueData === '') return false;
        var nameData = $self.attr('data-name');
        var $groupRadioButton = $('input[name=' + nameData + ']');
        $groupRadioButton.each(function () {
            var $self = $(this);
            if ($self.val() === valueData) {
                $self.prop('checked', true);
            }
        });
    });
	}
	
		//スクロール
	$(window).scroll(function () {
   	var body = window.document.body;
	  var html = window.document.documentElement;
	
	  var scrTop    = body.scrollTop || html.scrollTop;
	  var scrBottom = html.scrollHeight - html.clientHeight - scrTop;
	 
   	if(scrTop>=300){
	   	$(".pagetop").addClass("show");
   	}else{
	   	$(".pagetop").removeClass("show");
   	}
   	if(scrBottom < $(".footer-wrap").height() + $(".copyright-wrap").height()){
	   	$(".pagetop").addClass("white");
   	}else{
	   	$(".pagetop").removeClass("white");
   	}
  });
	//画面サイズごとの処理
	var w = $(window).width();
	deviceFunc(w);
	
	$(window).resize(function(){
  	var w = $(window).width();
	  deviceFunc(w);
	});
	
});

var dataLabelPlugin = {
	afterDatasetsDraw: function (chart, easing) {
      // To only draw at the end of animation, check for easing === 1
      var ctx = chart.ctx;

      chart.data.datasets.forEach(function (dataset, i) {
          var meta = chart.getDatasetMeta(i);
          if (!meta.hidden) {
              meta.data.forEach(function (element, index) {
                  // Draw the text in black, with the specified font
                  ctx.fillStyle = '#ffffff';

                  var fontSize = 14;
                  var fontStyle = 'normal';
                  var fontFamily = 'Helvetica Neue';
                  ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                  // Just naively convert to string for now
                  var dataString = dataset.data[index].toString() + "%";

                  // Make sure alignment settings are correct
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
										
                  
									var position = element.tooltipPosition();
									var padx = (element._model.x-position.x)/2.5;
									var pady = (element._model.y-position.y)/2.5;
									var px = position.x + 3;
                  var py = position.y - (fontSize / 2) + 5;
									
                  ctx.fillText(dataString, px - padx,py - pady);
              });
          }
          
                 
      });
  }
};
//画面サイズごとの処理
function deviceFunc(w){
	var bp = [599,960,1280];
	if (w <= bp[0]) {
		//sp
		$(".tel-number").each(function(){
			if(!$(this).parent(".tel-wrap").length){
				var no = $(this).data("tel");
				$(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
			}
		});
  } else if(w <= bp[1]){
		//tb
		$(".tel-wrap .tel-number").each(function(){
			$(this).unwrap();
		});
  } else if(w <= bp[2]){
		//small pc
  } else{
  
  }
}

/*ブラウザ判別*/
function browserCheck(){
	var ua = window.navigator.userAgent.toLowerCase();
	var av = window.navigator.appVersion.toLowerCase();
	if(ua.indexOf('edge') != -1){
		return 'edge';
	}else if (ua.indexOf('opera') != -1) {
  	return 'opera';
	}else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
		$("body").addClass('ie');
  	if (av.indexOf("msie 6.") != -1) {
    	return 'ie6';
  	} else if (av.indexOf("msie 7.") != -1) {
    	return 'ie7';
  	} else if (av.indexOf("msie 8.") != -1) {
    	return 'ie8';
  	} else if (av.indexOf("msie 9.") != -1) {
    	return 'ie9';
  	}else if (av.indexOf("msie 10.") != -1) {
    	return 'ie10';
  	}else if (av.indexOf("rv:11.") != -1) {
    	return 'ie11';
  	}
	} else if (ua.indexOf('chrome') != -1) {
  	return 'chrome';
	} else if (ua.indexOf('safari') != -1) {
  	return 'safari';
	} else if (ua.indexOf('gecko') != -1) {
  	return 'firefox';
	} else {
  	return false;
	}
}

/*OS判別*/
function osCheck(){
	var ua = window.navigator.userAgent.toLowerCase();
	if (ua.indexOf('windows') != -1) {
		if(ua.indexOf('phone') != -1){
			return 'winphone';
		}else{
			return 'win';
		}
	}else if(ua.indexOf('iphone') != -1) {
		return 'iphone';
	}else if(ua.indexOf('ipad') != -1) {
		return 'ipad';
	}else if(ua.indexOf('mac') != -1) {
		return 'mac';
	}else if(ua.indexOf('android') != -1) {
		if(ua.indexOf('mobile') != -1){
			if(ua.indexOf('A1_07') != -1){
				return 'tablet';
			}else if(ua.indexOf('SC-01C') != -1){
				return 'tablet';
			}else{	return 'android';}
		}else{
			return 'tablet';
		}
	}else{
		return false;
	}
}

/*デバイス判別*/
function deviceCheck(){
	var ua = window.navigator.userAgent.toLowerCase();
	if((ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) 
      || ua.indexOf("ipad") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
      || ua.indexOf("kindle") != -1
      || ua.indexOf("silk") != -1
      || ua.indexOf("playbook") != -1){
      return 'tab';
    }else if((ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
      || ua.indexOf("iphone") != -1
      || ua.indexOf("ipod") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
      || ua.indexOf("blackberry") != -1){
	    return 'sp';
    }else{
      return 'pc';
    }
}